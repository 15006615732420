import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Bathroom: IScene = {
	name: 'Upper Bathroom',
	pan: '0deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 41,
		y: 173,
	},
	images: Renderings.__upper_bathroom__,
	default: {
		latitude: -0.30798228473534106,
		longitude: 5.076528292574651,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.05643040034779512,
				longitude: 0.8111383906133425,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.17599320000192709,
					longitude: 0.5802057277656935,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.07768142434974146,
				longitude: 1.1978555468120602,
			},
			target: {
				name: 'Upper Hallway 2',
				view: {
					latitude: -0.1133039859484839,
					longitude: 1.5724169795552216,
				},
			},
		},
	],
};

export default __Upper_Bathroom;
