import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Powder: IScene = {
	name: 'Main Powder',
	pan: '0deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 148,
		y: 171,
	},
	images: Renderings.__main_powder__,
	default: {
		latitude: -0.21289894756089112,
		longitude: 0.5374605272676496,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.621184687966954,
				longitude: 3.440523878679411,
			},
			target: {
				name: 'Main Living Office',
				view: {
					latitude: -0.022874244583050984,
					longitude: 3.722808039297491,
				},
			},
		},
	],
};

export default __Main_Powder;
