import IScene from './IScene';
import __Basement_Entry from './__Basement_Entry';
import __Basement_Flex from './__Basement_Flex';
import __Basement_Hallway from './__Basement_Hallway';
import __Basement_Powder from './__Basement_Powder';
import __Main_Dining from './__Main_Dining';
import __Main_Hallway from './__Main_Hallway';
import __Main_Kitchen from './__Main_Kitchen';
import __Main_Living_Dining_Kitchen from './__Main_Living_Dining_Kitchen';
import __Main_Living_Office from './__Main_Living_Office';
import __Main_Powder from './__Main_Powder';
import __Upper_Bathroom from './__Upper_Bathroom';
import __Upper_Bedroom_2 from './__Upper_Bedroom_2';
import __Upper_Bedroom_3 from './__Upper_Bedroom_3';
import __Upper_Ensuite from './__Upper_Ensuite';
import __Upper_Hallway_1 from './__Upper_Hallway_1';
import __Upper_Hallway_2 from './__Upper_Hallway_2';
import __Upper_Hallway_3 from './__Upper_Hallway_3';
import __Upper_Master_Bedroom from './__Upper_Master_Bedroom';

const Scenes: Array<IScene> = [
	__Basement_Entry,
	__Basement_Flex,
	__Basement_Hallway,
	__Basement_Powder,
	__Main_Dining,
	__Main_Hallway,
	__Main_Kitchen,
	__Main_Living_Dining_Kitchen,
	__Main_Living_Office,
	__Main_Powder,
	__Upper_Bathroom,
	__Upper_Bedroom_2,
	__Upper_Bedroom_3,
	__Upper_Ensuite,
	__Upper_Hallway_1,
	__Upper_Hallway_2,
	__Upper_Hallway_3,
	__Upper_Master_Bedroom,
];

export default Scenes;
