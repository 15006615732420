import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_basement.svg';

const __Basement_Entry: IScene = {
	name: 'Basement Entry',
	pan: '0deg',
	level: 'basement',
	keyplate,
	hotspot: {
		x: 30,
		y: 224,
	},
	images: Renderings.__basement_entry__,
	default: {
		latitude: -0.15974516896810487,
		longitude: 0.013243505221711301,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: 0.06343155905563402,
				longitude: 6.275514202407177,
			},
			target: {
				name: 'Main Hallway',
				view: {
					latitude: -0.11285338456919236,
					longitude: 0.0043354612463208176,
				},
			},
		},
	],
};

export default __Basement_Entry;
