import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_basement.svg';

const __Basement_Hallway: IScene = {
	name: 'Basement Hallway',
	pan: '0deg',
	level: 'basement',
	keyplate,
	hotspot: {
		x: 64,
		y: 67,
	},
	images: Renderings.__basement_hallway__,
	default: {
		latitude: -0.1035553724079632,
		longitude: 2.172073741019011,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.49715719384594603,
				longitude: 4.736497513607644,
			},
			target: {
				name: 'Main Hallway',
				view: {
					latitude: -0.10617270242751164,
					longitude: 3.1326599014643617,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6080381487287378,
				longitude: 1.868682689778306,
			},
			target: {
				name: 'Basement Flex',
				view: {
					latitude: -0.1551988834837701,
					longitude: 1.8910908477657185,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7925402789240463,
				longitude: 3.116910622193402,
			},
			target: {
				name: 'Basement Powder',
				view: {
					latitude: -0.1779682572338981,
					longitude: 3.1062985744011717,
				},
			},
		},
	],
};

export default __Basement_Hallway;
