import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Kitchen: IScene = {
	name: 'Main Kitchen',
	pan: '0deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 131,
		y: 112,
	},
	images: Renderings.__main_kitchen__,
	default: {
		latitude: -0.19785313043574693,
		longitude: 0.6614748133829412,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3262830484728467,
				longitude: 5.253673326151765,
			},
			target: {
				name: 'Main Dining',
				view: {
					latitude: -0.13286782404003716,
					longitude: 5.327388295094544,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4584788626048697,
				longitude: 3.895888455656024,
			},
			target: {
				name: 'Main Living Dining Kitchen',
				view: {
					latitude: -0.24895356790286005,
					longitude: 3.764711304027318,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.1275518727723377,
				longitude: 4.400535664580758,
			},
			target: {
				name: 'Main Hallway',
				view: {
					latitude: -0.03251139205037168,
					longitude: 4.662397919500252,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2385099235870145,
				longitude: 3.2749960200383725,
			},
			target: {
				name: 'Main Living Office',
				view: {
					latitude: -0.1504449391759124,
					longitude: 3.2475009206184247,
				},
			},
		},
	],
};

export default __Main_Kitchen;
