import IImage from './IImage';

import __basement_entry__ from './unit-a-basement-entry.jpg';
import __basement_hallway__ from './unit-a-basement-hallway.jpg';
import __basement_powder__ from './unit-a-basement-powder.jpg';
import __basement_flex__ from './unit-a-basement-flex.jpg';
import __main_dining__ from './unit-a-main-dining.jpg';
import __main_hallway__ from './unit-a-main-hallway.jpg';
import __main_kitchen__ from './unit-a-main-kitchen.jpg';
import __main_living_dining_kitchen__ from './unit-a-main-living-dining-kitchen.jpg';
import __main_living_office__ from './unit-a-main-living-office.jpg';
import __main_powder__ from './unit-a-main-powder.jpg';
import __upper_hallway_1__ from './unit-a-upper-hallway-1.jpg';
import __upper_hallway_2__ from './unit-a-upper-hallway-2.jpg';
import __upper_hallway_3__ from './unit-a-upper-hallway-3.jpg';
import __upper_bathroom__ from './unit-a-upper-bathroom.jpg';
import __upper_master_bedroom__ from './unit-a-upper-master-bedroom.jpg';
import __upper_ensuite__ from './unit-a-upper-ensuite.jpg';
import __upper_bedroom_2__ from './unit-a-upper-bedroom-2.jpg';
import __upper_bedroom_3__ from './unit-a-upper-bedroom-3.jpg';

const Renderings: IImage = {
	__basement_entry__,
	__basement_hallway__,
	__basement_powder__,
	__basement_flex__,
	__main_dining__,
	__main_hallway__,
	__main_kitchen__,
	__main_living_dining_kitchen__,
	__main_living_office__,
	__main_powder__,
	__upper_hallway_1__,
	__upper_hallway_2__,
	__upper_hallway_3__,
	__upper_bathroom__,
	__upper_master_bedroom__,
	__upper_ensuite__,
	__upper_bedroom_2__,
	__upper_bedroom_3__,
};

export default Renderings;
