import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Hallway_2: IScene = {
	name: 'Upper Hallway 2',
	pan: '0deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 63,
		y: 165,
	},
	images: Renderings.__upper_hallway_2__,
	default: {
		latitude: -0.20690751878538904,
		longitude: 2.433364186550481,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.9292138470311411,
				longitude: 4.8847607572697,
			},
			target: {
				name: 'Upper Bathroom',
				view: {
					latitude: -0.36340300090874145,
					longitude: 4.425159693761618,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8276888114249843,
				longitude: 6.242055258224407,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.14004462734891154,
					longitude: 6.281369663382259,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.35195088337065306,
				longitude: 1.5465584609092229,
			},
			target: {
				name: 'Upper Hallway 3',
				view: {
					latitude: -0.06138602410018734,
					longitude: 1.549643525941929,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6578959018112505,
				longitude: 5.740606392848761,
			},
			target: {
				name: 'Main Hallway',
				view: {
					latitude: -0.03465394148968759,
					longitude: 1.466218035080922,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.47089505598579207,
				longitude: 6.251957371322319,
			},
			target: {
				name: 'Upper Bedroom 2',
				view: {
					latitude: -0.14998894541811403,
					longitude: 0.05079159317348988,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.40264100347126464,
				longitude: 3.0703445079182963,
			},
			target: {
				name: 'Upper Master Bedroom',
				view: {
					latitude: -0.05755867204193388,
					longitude: 3.1112698199938666,
				},
			},
		},
	],
};

export default __Upper_Hallway_2;
