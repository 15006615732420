import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Master_Bedroom: IScene = {
	name: 'Upper Master Bedroom',
	pan: '0deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 65,
		y: 237,
	},
	images: Renderings.__upper_master_bedroom__,
	default: {
		latitude: -0.1754525177139925,
		longitude: 0.7479788957053177,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.41800150872125585,
				longitude: 1.6028658599444994,
			},
			target: {
				name: 'Upper Ensuite',
				view: {
					latitude: -0.21140109036702803,
					longitude: 1.0018392811742811,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3236038029774191,
				longitude: 6.156636012440195,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.2254224873998254,
					longitude: 0.016759506358960653,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.41742936084592275,
				longitude: 6.129698764892152,
			},
			target: {
				name: 'Upper Hallway 2',
				view: {
					latitude: -0.21579645516719292,
					longitude: 6.268796838740893,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3886592896019758,
				longitude: 6.005519428973073,
			},
			target: {
				name: 'Upper Bathroom',
				view: {
					latitude: -0.18105404616748189,
					longitude: 5.897788079445946,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2297987894918183,
				longitude: 6.170208986408936,
			},
			target: {
				name: 'Upper Bedroom 2',
				view: {
					latitude: -0.028603805385710945,
					longitude: 6.2795619188682155,
				},
			},
		},
	],
};

export default __Upper_Master_Bedroom;
