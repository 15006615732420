import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Ensuite: IScene = {
	name: 'Upper Ensuite',
	pan: '0deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 139,
		y: 215,
	},
	images: Renderings.__upper_ensuite__,
	default: {
		latitude: -0.1754525177140147,
		longitude: 1.2098809629244531,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.39992655338570526,
				longitude: 4.5449586692674675,
			},
			target: {
				name: 'Upper Master Bedroom',
				view: {
					latitude: -0.0999709519831935,
					longitude: 4.08015210817008,
				},
			},
		},
	],
};

export default __Upper_Ensuite;
