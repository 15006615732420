import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Bedroom_2: IScene = {
	name: 'Upper Bedroom 2',
	pan: '0deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 62,
		y: 107,
	},
	images: Renderings.__upper_bedroom_2__,
	default: {
		latitude: -0.19192894684663298,
		longitude: 0.7578856424553297,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6482683397594022,
				longitude: 3.1140590427649517,
			},
			target: {
				name: 'Upper Hallway 1',
				view: {
					latitude: -0.14252944573183068,
					longitude: 3.1427202029621735,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.46918811596473287,
				longitude: 3.118876518413979,
			},
			target: {
				name: 'Upper Hallway 2',
				view: {
					latitude: -0.00020322603054889754,
					longitude: 3.100831248832288,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4480862096698859,
				longitude: 2.937753459830284,
			},
			target: {
				name: 'Upper Hallway 3',
				view: {
					latitude: -0.08431131935715497,
					longitude: 1.5061770828921348,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.23192559204987262,
				longitude: 3.1410226336228666,
			},
			target: {
				name: 'Upper Master Bedroom',
				view: {
					latitude: -0.11048351051080596,
					longitude: 3.0998081292373607,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4624481789408872,
				longitude: 3.273636135705439,
			},
			target: {
				name: 'Upper Bathroom',
				view: {
					latitude: -0.11603920373376209,
					longitude: 3.4262228166858044,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6162395233570246,
				longitude: 3.3800689281923146,
			},
			target: {
				name: 'Main Hallway',
				view: {
					latitude: -0.05879330355260759,
					longitude: 3.130719752365515,
				},
			},
		},
	],
};

export default __Upper_Bedroom_2;
