import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Hallway: IScene = {
	name: 'Main Hallway',
	pan: '0deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 26,
		y: 147,
	},
	images: Renderings.__main_hallway__,
	default: {
		latitude: -0.09606608643858605,
		longitude: 1.5553787558309955,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8132492943429557,
				longitude: 3.14493450044,
			},
			target: {
				name: 'Basement Entry',
				view: {
					latitude: -0.12289635517090725,
					longitude: 3.132734832875921,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7902048677010276,
				longitude: 0.00014431468983530332,
			},
			target: {
				name: 'Basement Hallway',
				view: {
					latitude: -0.03251139205037168,
					longitude: 4.662397919500252,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.39599143457553554,
				longitude: 1.5821272356191474,
			},
			target: {
				name: 'Main Living Dining Kitchen',
				view: {
					latitude: -0.007502366689504214,
					longitude: 1.5637258739538977,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.07114409502217978,
				longitude: 1.1595311780342297,
			},
			target: {
				name: 'Main Kitchen',
				view: {
					latitude: -0.18437241569086726,
					longitude: 1.1716722710083671,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.06256373667348658,
				longitude: 2.1474863586911574,
			},
			target: {
				name: 'Main Living Office',
				view: {
					latitude: -0.17524297204677008,
					longitude: 2.2513735265488344,
				},
			},
		},
	],
};

export default __Main_Hallway;
