import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Hallway_1: IScene = {
	name: 'Upper Hallway 1',
	pan: '0deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 61,
		y: 145,
	},
	images: Renderings.__upper_hallway_1__,
	default: {
		latitude: -0.23291177336919944,
		longitude: 5.700586635163057,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6832210393342439,
				longitude: 6.275661638755772,
			},
			target: {
				name: 'Upper Bedroom 2',
				view: {
					latitude: -0.18638485574568264,
					longitude: 6.27488503821392,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7496133418879993,
				longitude: 5.083639121351598,
			},
			target: {
				name: 'Main Hallway',
				view: {
					latitude: -0.3415475194936064,
					longitude: 2.0238555630785573,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.9684303556833873,
				longitude: 3.117164782721405,
			},
			target: {
				name: 'Upper Hallway 2',
				view: {
					latitude: -0.06461108536719751,
					longitude: 3.077302725301019,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7726737397762573,
				longitude: 2.473216926807753,
			},
			target: {
				name: 'Upper Hallway 3',
				view: {
					latitude: -0.0377610550245735,
					longitude: 1.544340533374782,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3062962071152189,
				longitude: 3.108001390850711,
			},
			target: {
				name: 'Upper Master Bedroom',
				view: {
					latitude: -0.08494970743149999,
					longitude: 3.094226926510939,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8706523707614786,
				longitude: 3.6922454490594467,
			},
			target: {
				name: 'Upper Bathroom',
				view: {
					latitude: -0.13273299305188235,
					longitude: 3.75645428813606,
				},
			},
		},
	],
};

export default __Upper_Hallway_1;
