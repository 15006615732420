import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_basement.svg';

const __Basement_Powder: IScene = {
	name: 'Basement Powder',
	pan: '0deg',
	level: 'basement',
	keyplate,
	hotspot: {
		x: 64,
		y: 100,
	},
	images: Renderings.__basement_powder__,
	default: {
		latitude: -0.27440264838153006,
		longitude: 5.327587908111123,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8063599801430756,
				longitude: 4.600286710552921,
			},
			target: {
				name: 'Basement Hallway',
				view: {
					latitude: -0.03251139205037168,
					longitude: 4.662397919500252,
				},
			},
		},
	],
};

export default __Basement_Powder;
