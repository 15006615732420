import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Living_Office: IScene = {
	name: 'Main Living Office',
	pan: '0deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 126,
		y: 215,
	},
	images: Renderings.__main_living_office__,
	default: {
		latitude: -0.17095894613237217,
		longitude: 5.3445405686960195,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3137765291821615,
				longitude: 5.753538642255405,
			},
			target: {
				name: 'Main Living Dining Kitchen',
				view: {
					latitude: -0.09644109469787798,
					longitude: 5.60155593900931,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.09303448480158005,
				longitude: 5.796612126815665,
			},
			target: {
				name: 'Main Dining',
				view: {
					latitude: -0.24932909420402005,
					longitude: 5.698726216081987,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5156783750364653,
				longitude: 0.25716225809598264,
			},
			target: {
				name: 'Main Powder',
				view: {
					latitude: -0.2531230404457192,
					longitude: 0.9389979887469189,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2375297775556473,
				longitude: 5.336642557399237,
			},
			target: {
				name: 'Main Hallway',
				view: {
					latitude: -0.07894496506051984,
					longitude: 5.297668054844543,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2250396105994632,
				longitude: 5.4170997628486175,
			},
			target: {
				name: 'Basement Hallway',
				view: {
					latitude: -0.22365606010228145,
					longitude: 2.1777757509601527,
				},
			},
		},
	],
};

export default __Main_Living_Office;
