import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_upper.svg';

const __Upper_Hallway_3: IScene = {
	name: 'Upper Hallway 3',
	pan: '0deg',
	level: 'upper',
	keyplate,
	hotspot: {
		x: 150,
		y: 165,
	},
	images: Renderings.__upper_hallway_3__,
	default: {
		latitude: -0.2143968047547613,
		longitude: 6.272059733165538,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3300659306277165,
				longitude: 4.720166074106101,
			},
			target: {
				name: 'Upper Hallway 2',
				view: {
					latitude: -0.12375138940551889,
					longitude: 4.720666408336253,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2689380478631149,
				longitude: 4.726966779173066,
			},
			target: {
				name: 'Upper Bathroom',
				view: {
					latitude: -0.15911543999297795,
					longitude: 4.698903793350421,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3183970766179294,
				longitude: 4.61602176161604,
			},
			target: {
				name: 'Upper Master Bedroom',
				view: {
					latitude: -0.467591162705955,
					longitude: 0.7458226834185689,
				},
			},
		},
	],
};

export default __Upper_Hallway_3;
