import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Dining: IScene = {
	name: 'Main Dining',
	pan: '0deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 61,
		y: 75,
	},
	images: Renderings.__main_dining__,
	default: {
		latitude: -0.13650823067322815,
		longitude: 2.4383175599254834,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.15999101466733,
				longitude: 1.9479072925829815,
			},
			target: {
				name: 'Main Kitchen',
				view: {
					latitude: -0.040982262278705495,
					longitude: 2.1381739327264953,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.18284221254336175,
				longitude: 2.6263311942831726,
			},
			target: {
				name: 'Main Living Office',
				view: {
					latitude: -0.1801407738125036,
					longitude: 2.464259114457325,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.21242319576253488,
				longitude: 2.947098333874865,
			},
			target: {
				name: 'Main Living Dining Kitchen',
				view: {
					latitude: -0.16806927943356698,
					longitude: 2.697259341713926,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.38760062055168976,
				longitude: 3.362360834899554,
			},
			target: {
				name: 'Main Hallway',
				view: {
					latitude: -0.1410018022548627,
					longitude: 3.1293131457385712,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.21133135525188718,
				longitude: 3.428015678178309,
			},
			target: {
				name: 'Basement Entry',
				view: {
					latitude: -0.13787398307654275,
					longitude: 3.121783313768907,
				},
			},
		},
	],
};

export default __Main_Dining;
