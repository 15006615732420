import SCSS from './Caption.module.scss';

type Props = {
	name: string;
};

const Caption = (props: Props) => {
	const {name} = props;
	return (
		<div className={SCSS.Caption}>
			<div className={SCSS.Inner}>
				<div className={SCSS.UnitLabel}>
					<h1>Unit A</h1>
					<h3>{name}</h3>
				</div>
			</div>
		</div>
	);
};

export default Caption;
