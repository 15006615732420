export const Circle = (size: number): string => {
	const speed = '2.5s';
	// const color = "#96ca50";
	// const color = '#ffffff';
	// const color = '#1C2859';
	const color = '#42A5DE';
	const opacity = 1;
	const stroke = 15;
	const rstart: number = Math.random() * 1.5;
	const output = `<svg width='${size}' height='${size}' ><g transform='translate(${
		size / 2
	}, ${size / 2})'><circle cx='0' cy='0' r='${
		(size - 4) / 2
	}' fill='none' stroke='${color}' scale='0' opacity='0'><animateTransform attributeName='transform' type='scale' additive='sum' values="0;1" begin='${rstart}s' dur='${speed}' repeatCount='indefinite'></animateTransform><animate attributeName='stroke-width' values='0;${
		stroke / 2
	};${stroke};${
		stroke / 2
	};0' begin='${rstart}s' dur='${speed}' repeatCount='indefinite' /><animate attributeName='opacity' values='0;${
		opacity / 2
	};${opacity};${
		opacity / 2
	};0' begin='${rstart}s' dur='${speed}' repeatCount='indefinite' /></circle></g></svg>`;
	return output;
};
