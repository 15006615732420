import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_basement.svg';

const __Basement_Flex: IScene = {
	name: 'Basement Flex',
	pan: '0deg',
	level: 'basement',
	keyplate,
	hotspot: {
		x: 92,
		y: 87,
	},
	images: Renderings.__basement_flex__,
	default: {
		latitude: -0.13422888276951372,
		longitude: 1.56169151832794,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6124292035743646,
				longitude: 5.764995257172676,
			},
			target: {
				name: 'Basement Hallway',
				view: {
					latitude: -0.17399222125176994,
					longitude: 4.706946416356682,
				},
			},
		},
	],
};

export default __Basement_Flex;
