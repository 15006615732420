import IScene from './IScene';
import Renderings from '../renderings';
import generateHash from 'random-hash';
import keyplate from '../keyplates/key_main.svg';

const __Main_Living_Dining_Kitchen: IScene = {
	name: 'Main Living Dining Kitchen',
	pan: '0deg',
	level: 'main',
	keyplate,
	hotspot: {
		x: 85,
		y: 144,
	},
	images: Renderings.__main_living_dining_kitchen__,
	default: {
		latitude: -0.14859713591318258,
		longitude: 3.1418246021205527,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3458661638432148,
				longitude: 2.3804554229098023,
			},
			target: {
				name: 'Main Living Office',
				view: {
					latitude: -0.06817863593743079,
					longitude: 2.6635249617768864,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4786282980274805,
				longitude: 4.8546659034802,
			},
			target: {
				name: 'Main Hallway',
				view: {
					latitude: 0.01687112149569092,
					longitude: 4.606612543457053,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.1985159105493175,
				longitude: 5.901224827779991,
			},
			target: {
				name: 'Main Dining',
				view: {
					latitude: -0.12393581377827889,
					longitude: 5.767277132607743,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.18234110099621859,
				longitude: 0.7560457575845725,
			},
			target: {
				name: 'Main Kitchen',
				view: {
					latitude: -0.20684027359900092,
					longitude: 0.7011018003829745,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.49115219250155384,
				longitude: 5.049935481120865,
			},
			target: {
				name: 'Basement Hallway',
				view: {
					latitude: -0.3233683048291982,
					longitude: 2.7742837568470637,
				},
			},
		},
	],
};

export default __Main_Living_Dining_Kitchen;
